import React from 'react'
import Parallexsection from '../component/Parallexsection'

function Tracksheet() {
  return (
    <div>
      <Parallexsection
         heading="Tracksheet"
        heading2="Tracksheet"
    />
    </div>
  )
}



export default  Tracksheet

// ServiceCard.jsx
import React from 'react';
import { useNavigate } from "react-router-dom";
import './ServiceCard.css';

const ServiceCard = ({ image, title,route }) => {
  const navigate = useNavigate();
  return (
    <div className="service-card">
      <img src={image} alt={title} className="service-card-image" />
      <div className="service-card-content">
        <h3>{title}</h3>
        <hr style={{color:'white'}}/>
        <p id="servicecardpara">Become faster, more flexible, and intensely customer-focused.</p>
        <p id="servicecardbutton" onClick={() => navigate(route)}>More Details →</p>
      </div>
    </div>
  );
};

export default ServiceCard;

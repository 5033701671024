import React from 'react';
import FeaturesDemoCall from '../component/FeaturesDemoCall';
import Parallexsection from '../component/Parallexsection';
import PricingPlan from '../component/PricingPlan';

 function Classicstockfuture() {

  const monthlyPlan = {
    title: 'Monthly',
    price: 'Rs 12000',
    features: [
      '1-2 Intraday Equity signal with set Target & SL.',
      'Follow-up on trade signals.',
      'Morning & Closing Bell update.',
      'Nifty review, Key Resistance & Support point.',
      'Global market & Economic data update.',
      'Stock in News, RBI Policy, IIP, CPI update.'
    ]
  };




  const quarterlyPlan = {
    title: 'Quarterly',
    price: 'Rs 30000',
    features: [
      '1-2 Intraday Equity signal with set Target & SL.',
      'Follow-up on trade signals.',
      'Morning & Closing Bell update.',
      'Nifty review, Key Resistance & Support point.'
,      'Global market & Economic data update.',
      'Stock in News, RBI Policy, IIP, CPI update.'
    ]
  };




  const features = [
    { label: ' Minimum Investment', value: '12-18 Equity Cash Recommendations' },
    { label: 'Recommendation Frequency', value: 'Yes' },
    { label: 'Mode of recommendation', value: 'SMS Only' },
    { label: 'Follow Up', value: 'Yes, SMS' },
    { label: 'Risk', value: 'High' }
  ];






  const demoCall = 'Demo call : Buy SBIN fut at 575 Target 585 Stoploss 566';

  return (
    <div>
      <Parallexsection
         heading="Classic Stock Future"
        heading2="Classicstockfuture"
    />

<div>
  <FeaturesDemoCall features={features} demoCall={demoCall} />
  </div>

  <div className='pricingMasterContainer'>
<PricingPlan {...monthlyPlan} />
<PricingPlan {...quarterlyPlan} />
</div>
    </div>
  )
}


export default Classicstockfuture

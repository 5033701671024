import React from 'react'
import Parallexsection from '../component/Parallexsection'

 function Complaintstatus() {
  return (
    <div>
         <Parallexsection
         heading="Complaint Status"
        heading2="Complaintstatus"
    />
    </div>
  )
}


export default Complaintstatus

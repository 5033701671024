// PricingPlan.js
import React from 'react';
import './PricingPlan.css';

const PricingPlan = ({ title, price, features }) => {
  return (
    <div className="pricing-plan">
      <h2>{title}</h2>
      <h3>{price}</h3>
      <hr />
      <ul>
        {features.map((feature, index) => (
          <li key={index}>
            <span>{feature}</span>
            <span className="check-mark">✔</span>
          </li>
        ))}
      </ul>
      <button>Buy This Plan →</button>
    </div>
  );
};

export default PricingPlan;

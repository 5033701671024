import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import Parallexsection from '../component/Parallexsection';
import faqImage from '../images/banner-meeting-2b.jpg'; // Make sure to provide the correct path to your image
import './FAQ.css';

function FAQ() {
  const questions = [
    {
      question: "What is Real Stock Expert?",
      answer: "Real Stock Expert is a SEBI registered Research Analyst providing equity market services in Indian stock market. Our in-depth analysis and experimented techniques gives results to our esteemed clients. We believe in our core values and always follow our principals."
    },
    {
      question: "Real Stock Expert registered with SEBI?",
      answer: "Yes, Real Stock Expert is registered with SEBI."
    },
    {
      question: "Is there any complaint helpline available?",
      answer: "Yes, you can reach our complaint helpline at 1800-123-456."
    },
    {
      question: "Do you provide Equity intraday trading tips?",
      answer: "Yes, we provide intraday calls in Equity, Derivative and index option (Nifty, Banknifty, Finnifty, Sensex)."
    }
  ];

  const [openIndex, setOpenIndex] = useState(null);
  const [heights, setHeights] = useState({});
  const answerRefs = useRef([]);

  useEffect(() => {
    // Calculate and set the height for each answer
    const newHeights = {};
    answerRefs.current.forEach((ref, index) => {
      if (ref) {
        newHeights[index] = ref.scrollHeight;
      }
    });
    setHeights(newHeights);
  }, []);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      <Parallexsection
        heading="FAQ’s"
        heading2="FAQ’s"
      />
      <div className="faq-container">
        <img className="faq-image" src={faqImage} alt="FAQ Image" />
        <div className="questions-container">
          <h2>Question & Answer</h2>
          {questions.map((item, index) => (
            <div key={index} className="question-container">
              <div className="question" onClick={() => handleToggle(index)}>
                <div className="question-title">{`${index + 1}. ${item.question}`}</div>
                <FontAwesomeIcon icon={openIndex === index ? faMinus : faPlus} />
              </div>
              <div
                className="answer"
                style={{
                  height: openIndex === index ? `${heights[index]}px` : '0px',
                  opacity: openIndex === index ? 1 : 0
                }}
                ref={el => (answerRefs.current[index] = el)}
              >
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQ;

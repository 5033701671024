import React from 'react';
import FeaturesDemoCall from '../component/FeaturesDemoCall';
import Parallexsection from '../component/Parallexsection';
import PricingPlan from '../component/PricingPlan';

function Platinumstockoption() {
  const monthlyPlan = {
    title: 'Monthly',
    price: 'Rs 30000',
    features: [
      '1-2 Intraday Equity signal with set Target & SL.',
      'Follow-up on trade signals.',
      'Morning & Closing Bell update.',
      'Nifty review, Key Resistance & Support point.',
      'Global market & Economic data update.',
      'Stock in News, RBI Policy, IIP, CPI update.',
      '24/7 Customer support'
    ]
  };


  const quarterlyPlan = {
    title: 'Quarterly',
    price: 'Rs 75000',
    features: [
      '1-2 Intraday Equity signal with set Target & SL.',
      'Follow-up on trade signals.',
      'Morning & Closing Bell update.',
      'Nifty review, Key Resistance & Support point.',
      'Global market & Economic data update.',
      'Stock in News, RBI Policy, IIP, CPI update.',
      '24/7 Customer support'
    ]
  };






  const features = [
    { label: 'Minimum Investment', value: '1,00,000' },
    { label: 'Recommendation Frequency', value: '30-45 monthly' },
    { label: 'Mode of recommendation', value: 'SMS Only' },
    { label: 'Follow Up', value: 'Yes, SMS' },
    { label: 'Risk', value: 'High' }
  ];


  const demoCall = 'Demo call : Buy Reliance Sep 2440 CE at 48 Target 60 Stoploss 37';

  return (
    <div>
       <Parallexsection
         heading="Platinum Stock Option"
        heading2="Platinumstockoption"
       
    />

<div>
  <FeaturesDemoCall features={features} demoCall={demoCall} />
  </div>

  <div className='pricingMasterContainer'>
<PricingPlan {...monthlyPlan} />
<PricingPlan {...quarterlyPlan} />
</div>
    </div>
  )
}



export default  Platinumstockoption

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import contactimg from "../images/contact-jpg.jpg";
import Loader from "../Loader/Loader"; // Import the Loader component
import "./Contact.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    segment: "",
    capital: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Add loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && !/^\d*$/.test(value)) {
      return; // Allow only digits for phone number
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let tempErrors = { ...errors };
    switch (name) {
      case "name":
        tempErrors.name = value ? "" : "Name is required";
        break;
      case "email":
        tempErrors.email = value ? (/\S+@\S+\.\S+/.test(value) ? "" : "Email is invalid") : "Email is required";
        break;
      case "phone":
        tempErrors.phone = value
          ? value.length === 10
            ? ""
            : "Phone must be a 10-digit number"
          : "Phone is required";
        break;
      case "segment":
        tempErrors.segment = value ? "" : "Segment is required";
        break;
      case "capital":
        tempErrors.capital = value ? "" : "Capital investment is required";
        break;
      case "message":
        tempErrors.message = value ? "" : "Message is required";
        break;
      default:
        break;
    }
    setErrors(tempErrors);
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required";
    if (!formData.email) tempErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email)) tempErrors.email = "Email is invalid";
    if (!formData.phone) tempErrors.phone = "Phone is required";
    if (formData.phone.length !== 10) tempErrors.phone = "Phone must be a 10-digit number";
    if (!formData.segment) tempErrors.segment = "Segment is required";
    if (!formData.capital) tempErrors.capital = "Capital investment is required";
    if (!formData.message) tempErrors.message = "Message is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true); // Show loader
      try {
        const response = await axios.post("https://realstock.onrender.com/api/userData", {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          segment: formData.segment,
          investment: parseFloat(formData.capital.replace(/[^0-9.]/g, "")), // Convert to number
          message: formData.message,
        });
        console.log(response.data);
        alert("Form submitted successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          segment: "",
          capital: "",
          message: "",
        }); // Clear form data
      } catch (error) {
        console.error("There was an error submitting the form!", error);
        alert("Failed to submit the form. Please try again later.");
      } finally {
        setLoading(false); // Hide loader
      }
    }
  };

  return (
    <div className="contact-container">
     

      <div className="contact-form">
        <h2>Connect With Us</h2>
        <p>Fields marked with an * are required</p>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name">Name*</label>
            <div className="input-with-icon">
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {formData.name && !errors.name && (
                <FontAwesomeIcon icon={faCheckCircle} className="valid-icon" />
              )}
            </div>
            {errors.name && <span className="error">{errors.name}</span>}
          </div>
          <div>
            <label htmlFor="email">Email*</label>
            <div className="input-with-icon">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {formData.email && !errors.email && (
                <FontAwesomeIcon icon={faCheckCircle} className="valid-icon" />
              )}
            </div>
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div>
            <label htmlFor="phone">Phone*</label>
            <div className="input-with-icon">
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                maxLength="10"
              />
              {formData.phone.length === 10 && !errors.phone && (
                <FontAwesomeIcon icon={faCheckCircle} className="valid-icon" />
              )}
            </div>
            {errors.phone && <span className="error">{errors.phone}</span>}
          </div>
          <div>
            <label htmlFor="segment">In which segment do you trade*</label>
            <select
              id="segment"
              name="segment"
              value={formData.segment}
              onChange={handleChange}
              required
            >
              <option value="">Select a segment</option>
              <option value="options">Options</option>
              <option value="equity">Equity</option>
              <option value="banknifty">BankNifty (Call-Put)</option>
              <option value="nifty50">Nifty50</option>
              <option value="finnifty">Finnifty</option>
              <option value="midcapnifty">MidcapNifty</option>
              <option value="sensex">Sensex</option>
            </select>
            {errors.segment && <span className="error">{errors.segment}</span>}
          </div>
          <div>
            <label htmlFor="capital">How much is your capital investment*</label>
            <select
              id="capital"
              name="capital"
              value={formData.capital}
              onChange={handleChange}
              required
            >
              <option value="">Select an amount</option>
              <option value="30000-50000">30,000 to 50,000</option>
              <option value="1-3-lakh">1 Lakh to 3 Lakh</option>
              <option value="5-9-lakh">5 Lakh to 9 Lakh</option>
              <option value="10-15-lakh">10 Lakh to 15 Lakh</option>
              <option value="more-than-20-lakh">More than 20 Lakh</option>
            </select>
            {errors.capital && <span className="error">{errors.capital}</span>}
          </div>
          <div>
            <label htmlFor="message">Message</label>
            <div className="input-with-icon">
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              {formData.message && !errors.message && (
                <FontAwesomeIcon icon={faCheckCircle} className="valid-icon" />
              )}
            </div>
            {errors.message && <span className="error">{errors.message}</span>}
          </div>
          <button type="submit" disabled={loading}>Submit</button>
        </form>
        {loading && <Loader />} {/* Show loader */}
      </div>

      <div className="contact-image">
        <img src={contactimg} alt="Contact Us" />
      </div>
    </div>
  );
}

export default Contact;

// stockService.js
import axios from 'axios';

const API_KEY = 'FPOIGD8T2ABC48AL';



const getStockData = async (symbol) => {
    const url = `https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${symbol}&apikey=${API_KEY}`;
    const response = await axios.get(url);
    return response.data['Global Quote'];
  };

export default getStockData;






import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem('token'); // Or use a context for more complex auth

  return isAuthenticated ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/Admin" />
  );
};

export default PrivateRoute;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ServiceContainer.css'; // Assuming you have CSS for styling

const ServiceContainer = ({ title,route }) => {
  const navigate = useNavigate();
  return (
    <div className="service-container" onClick={()=>navigate(route)}>
      <h3>{title}</h3>
    </div>
  );
};

export default ServiceContainer;

import React from 'react'
import Parallexsection from '../component/Parallexsection'
import Services from './Service'

function ServicePage() {
  return (
    <div>
        <Parallexsection
         heading="Service  we Provide"
        heading2="Service"
    />
    <Services/>
    </div>
  )
}



export default ServicePage

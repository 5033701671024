import React from 'react'
import Parallexsection from '../component/Parallexsection'
import Contact from './Contact'

function ContactPage() {
  return (
    <div>
      <Parallexsection
         heading="Contact"
        heading2="Contact"
    />

    <Contact/>
    </div>
  )
}



export default ContactPage

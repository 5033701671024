// FeaturesDemoCall.js
import React from 'react';
import './FeaturesDemoCall.css';

const FeaturesDemoCall = ({ features, demoCall }) => {
  return (
    <div className="features-demo-call">
      <div className="features">
        <h2>Features</h2>
        <ul>
          {features.map((feature, index) => (
            <li key={index}>
              <strong>{feature.label}:</strong> {feature.value}
            </li>
          ))}
        </ul>
      </div>
      <div className="demo-call">
        <h2 style={{marginLeft:'50px', fontSize:'70px', color:'white'}}>📞</h2>
        <p style={{color:'white'}}>{demoCall}</p>
      </div>
    </div>
  );
};

export default FeaturesDemoCall;

import React from 'react';
import Parallexsection from '../component/Parallexsection';
import About from './About';
import './AboutPage.css'; // Make sure to import the CSS file

function AboutPage() {
  return (
    <div>
      <Parallexsection
        heading="About"
        heading2="About"
      />
      <About />
      <div className="vision-objective-mission">
        <div className="section">
          <div className="icon">🌟</div>
          <h2>Vision</h2>
          <p>To be recognised as one of the most trusted and respected equity investment research analysts.</p>
        </div>
        <div className="section">
          <div className="icon">🌐</div>
          <h2>Objective</h2>
          <p>Providing quality research at an affordable price. Helping investors create long-term wealth through value investing and the power of compounding. Provide the safest and most secure investment guidance to traders.</p>
        </div>
        <div className="section">
          <div className="icon">💰</div>
          <h2>Mission</h2>
          <p>To deliver outstanding investment results with calculated risks and utmost integrity.</p>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;

import React from 'react'
import Parallexsection from '../component/Parallexsection'

function DisclosuresAdvise() {
  return (
    <div>
          <Parallexsection
         heading="Disclosures With Advise"
        heading2="Disclosures With Advise"
    />
    </div>
  )
}


export default  DisclosuresAdvise

import { motion } from 'framer-motion';
import React from 'react';
import AboutSection from '../About/About';
import CompanyScroller from '../CompanyScroller/CompanyScroller';
import Contact from '../Contact/Contact';
import CustomCarousel from '../CustomCarousel/CustomCarousel';
import LetsDiscussSection from '../LetsDiscussSection/LetsDiscussSection';
import Services from '../Service/Service';
import ImageSlider from '../Slider/ImageSlider';

const slideInFromLeft = {
  hidden: { opacity: 0, x: '-100%' },
  visible: { opacity: 1, x: 0 },
};

const slideInFromRight = {
  hidden: { opacity: 0, x: '100%' },
  visible: { opacity: 1, x: 0 },
};

function Home() {
  return (
    <div>
      <CustomCarousel />
      <motion.div
        initial="hidden"
        animate="visible"
        variants={slideInFromRight}
        transition={{ duration: 0.8 }}
      >
        <CompanyScroller />
      </motion.div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={slideInFromLeft}
        transition={{ duration: 0.8 }}
      >
        <Contact />
      </motion.div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={slideInFromRight}
        transition={{ duration: 0.8 }}
      >
        <AboutSection />
      </motion.div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={slideInFromLeft}
        transition={{ duration: 0.8 }}
      >
        <Services />
      </motion.div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={slideInFromRight}
        transition={{ duration: 0.8 }}
      >
        <LetsDiscussSection />
      </motion.div>
      <div style={{ background: 'linear-gradient(135deg, #f0f4f8, #e0e7ee)' }}>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={slideInFromLeft}
          transition={{ duration: 0.8 }}
        >
          <ImageSlider />
        </motion.div>
      </div>
    </div>
  );
}

export default Home;

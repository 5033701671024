// CompanyScroller.js
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Lnt from '../images/LT.png';
import tatamotor from '../images/TATA-Motors.jpg';
import tcs from '../images/TCS.NS.png';
import adani from '../images/adani.png';
import HINDALCO from '../images/hindalco_logo.png';
import icici from '../images/icici-bank.png';
import india from '../images/india.png';
import reliance from '../images/reliance.png';
import sbi from '../images/sbi-logo.png';
import tatapower from '../images/tata-power-logo.png';
import getStockData from '../stockService';

const scroll = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const Scroller = styled.div`
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  background-color: #f0f0f0;
  padding: 10px 0;
`;

const ScrollingText = styled.div`
  display: inline-block;
  animation: ${scroll} 20s linear infinite;
`;

const CompanyContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0 20px;
  cursor: pointer;
  color: #0073e6;

  &:hover {
    text-decoration: underline;
  }
`;

const Logo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const StockPrice = styled.span`
  margin-left: 10px;
  font-weight: bold;
`;

const PriceChange = styled.span`
  margin-left: 10px;
  color: ${props => (props.change > 0 ? 'green' : 'red')};
`;

const companies = [
  {
    name: 'TCS',
    symbol: 'TCS',
    link: 'https://finance.stockdio.com/profile/INDIA_NSE/TCS?s=1',
    logo: tcs,
    price:'320'
  },
  {
    name: 'Adani',
    symbol: 'ADANIPOWER.BSE',
    link: 'https://finance.stockdio.com/profile/INDIA_NSE/ADANI?s=1',
    logo: adani
  },
  {
    name: 'Tata Motors',
    symbol: 'TTM',
    link: 'https://finance.stockdio.com/profile/INDIA_NSE/TATAMOTORS?s=1',
    logo: tatamotor
  },


  {
    name: 'Reliance',
    symbol: 'Reliance',
     link: 'https://finance.stockdio.com/profile/INDIA_NSE/RELIANCE?s=1',
    logo: reliance
   
  },

  {
    name: 'SBIN',
    symbol: 'SBIN',
    link:'https://finance.stockdio.com/profile/INDIA_NSE/SBIN?s=1',
    logo:sbi
  },
  {
    name: 'LARSEN & TURBO',
    symbol: 'Reliance',
    link:'https://finance.stockdio.com/profile/INDIA_NSE/LT?s=1',
    logo:Lnt
  },
  {
    name: 'ECNXNIFTY',
    symbol: 'ECNXNIFTY',
    link:'https://finance.stockdio.com/profile/INDIA_NSE/%5ECNXNIFTY?s=1',
    logo:india
  },
  {
    name: 'HINDALCO',
    symbol: 'HINDALCO',
    link:'https://finance.stockdio.com/profile/INDIA_NSE/HINDALCO?s=1',
    logo:HINDALCO

  },
  {
    name: 'ICICIBANK',
    symbol: 'ICICIBANK',
    link:'https://finance.stockdio.com/profile/INDIA_NSE/ICICIBANK?s=1',
    logo:icici
  },
  {
    name: 'INR/USD',
    symbol: 'INR/USD',
    link:'https://finance.stockdio.com/profile/FOREX/INR/USD?s=1',
    logo:india
  },
  {
    name: 'TATAPOWER',
    symbol: 'TATAPOWER',
    link:'https://finance.stockdio.com/profile/INDIA_NSE/TATAPOWER?s=1',
    logo:tatapower
  },
  {
    name: 'Reliance',
    symbol: 'Reliance',
    link:'https://finance.stockdio.com/profile/INDIA_NSE/%5ECNXNIFTY?s=1',
    logo:tcs
  }, 
 


  // Add more companies here
];

const CompanyScroller = () => {
  const [stockData, setStockData] = useState({});

  const fetchStockData = async () => {
    const data = {};
    for (const company of companies) {
      const stockInfo = await getStockData(company.symbol);
      data[company.symbol] = stockInfo;
    }
    setStockData(data);
  };

  useEffect(() => {
    fetchStockData();
    const intervalId = setInterval(fetchStockData, 60000); // Update every minute
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return (
    <Scroller>
      <ScrollingText>
        {companies.map((company, index) => {
          const stockInfo = stockData[company.symbol];
          return (
            <CompanyContainer
              key={index}
              onClick={() => window.open(company.link, '_blank')}
            >
              <Logo src={company.logo} alt={`${company.name} logo`} />
              <span>{company.name}</span>
              {stockInfo && (
                <>
                  <StockPrice>{parseFloat(stockInfo['05. price']).toFixed(2)}</StockPrice>
                  <PriceChange change={parseFloat(stockInfo['09. change'])}>
                    {parseFloat(stockInfo['09. change']) > 0 ? `+${parseFloat(stockInfo['09. change']).toFixed(2)}` : parseFloat(stockInfo['09. change']).toFixed(2)}
                  </PriceChange>
                </>
              )}
            </CompanyContainer>
          );
        })}
      </ScrollingText>
    </Scroller>
  );
};

export default CompanyScroller;

import React, { useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import './AdminLogin.css';

function AdminLogin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validate = () => {
    const errors = {};
    if (!username) errors.username = 'Username is required';
    if (!password) errors.password = 'Password is required';
    return errors;
  };

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const response = await fetch('https://realstock.onrender.com/api/auth', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, password }),
        });

        const data = await response.json();
        setLoading(false);

        if (response.ok) {
          // console.log('Form submitted:', { username, password });
          // Save the token to local storage
          localStorage.setItem('token', data.token);
          // Handle successful login here, e.g., redirect, update UI, etc.
          alert('Login successful');
          setUsername('');
          setPassword('');
          setTouched({});
          setErrors({});
          navigate('/Admin/home');
        } else {
          // Handle errors from the server
          if (response.status === 404) {
            alert('Login failed: Endpoint not found (404).');
          } else if (response.status === 401) {
            alert('Login failed: Invalid credentials.');
          } else {
            alert(`Login failed: ${data.msg}`);
          }
        }
      } catch (error) {
        setLoading(false);
        alert('An error occurred. Please try again.');
        console.error('Error:', error);
      }
    }
  };

  const isFieldValid = (field) => {
    return touched[field] && !errors[field];
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Admin Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">Username</label>
            <div className="input-wrapper">
              <input
                type="text"
                id="username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onBlur={() => handleBlur('username')}
                required
                autoComplete="username"
              />
              {isFieldValid('username') ? (
                <FaCheckCircle className="valid-icon" />
              ) : (
                touched.username && <FaTimesCircle className="error-icon" />
              )}
            </div>
            {errors.username && touched.username && (
              <div className="error-message">{errors.username}</div>
            )}
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <div className="input-wrapper">
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() => handleBlur('password')}
                required
                autoComplete="current-password"
              />
              {isFieldValid('password') ? (
                <FaCheckCircle className="valid-icon" />
              ) : (
                touched.password && <FaTimesCircle className="error-icon" />
              )}
            </div>
            {errors.password && touched.password && (
              <div className="error-message">{errors.password}</div>
            )}
          </div>
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
        {loading && <Loader />} 
      </div>
    </div>
  );
}

export default AdminLogin;

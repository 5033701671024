import React from 'react'
import Parallexsection from '../component/Parallexsection'

function Bankdetails() {
  return (
    <div>
        <Parallexsection
         heading="Bank Details"
        heading2="bank-detail"
    />
    </div>
  )
}




export default Bankdetails

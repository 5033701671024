import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import img_1 from '../images/img_1.jpeg';
import img_11 from '../images/img_11.jpeg';
import img_12 from '../images/img_12.jpeg';
import img_13 from '../images/img_13.jpeg';
import img_14 from '../images/img_14.jpeg';
import img_15 from '../images/img_15.jpeg';
import img_16 from '../images/img_16.jpeg';
import img_17 from '../images/img_17.jpeg';
import img_18 from '../images/img_18.jpeg';
import img_19 from '../images/img_19.jpeg';
import img_2 from '../images/img_2.jpeg';
import img_20 from '../images/img_20.jpeg';
import img_3 from '../images/img_3.jpeg';
import img_4 from '../images/img_4.jpeg';
import img_5 from '../images/img_5.jpeg';
import img_6 from '../images/img_6.jpeg';
import img_7 from '../images/img_7.jpeg';
import img_8 from '../images/img_8.jpeg';
import img_9 from '../images/img_9.jpeg';
import './ImageSlider.css';

const ImageSlider = () => {
  const [zoomed, setZoomed] = useState(null);

  const images = [
    img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9,
    img_11, img_12, img_13, img_14, img_15, img_16, img_17, img_18, img_19, img_20,
  ];

  const handleZoom = (image) => {
    console.log("Image clicked:", image);
    setZoomed(image);
  };

  const handleCloseZoom = () => {
    console.log("Close zoom clicked");
    setZoomed(null);
  };

  const renderCustomPrevArrow = (onClickHandler, hasPrev, label) =>
    hasPrev && (
      <button type="button" onClick={onClickHandler} title={label} className="custom-arrow custom-prev-arrow">
        &#10094; {/* Unicode for left arrow */}
      </button>
    );

  const renderCustomNextArrow = (onClickHandler, hasNext, label) =>
    hasNext && (
      <button type="button" onClick={onClickHandler} title={label} className="custom-arrow custom-next-arrow">
        &#10095; {/* Unicode for right arrow */}
      </button>
    );

  return (
    <>
      {zoomed && (
        <div className="overlay" onClick={handleCloseZoom}>
          <img src={zoomed} alt="Zoomed" className="zoomed" />
        </div>
      )}
      <Carousel
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        useKeyboardArrows={true}
        autoPlay={true}
        dynamicHeight={false}
        swipeable={true}
        emulateTouch={true}
        centerMode={true}
        centerSlidePercentage={33.3}
        className="carousel-slider"
        renderArrowPrev={renderCustomPrevArrow}
        renderArrowNext={renderCustomNextArrow}
      >
        {images.map((image, index) => (
          <div key={index} className="imgContainerSlider">
            <img
              src={image}
              alt={`slide-${index}`}
              onClick={() => handleZoom(image)}
            />
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default ImageSlider;

import React from 'react';
import Parallexsection from '../component/Parallexsection';
import ServiceContainer from '../Service/ServiceContainer';

function EquityPage() {

  const services = [
    { name: "Classic Equity", route: "/services/classic-equity" },
    { name: "Platinum Equity", route: "/services/platinum-equity" },
    { name: "BTST/Short Term/Long Term", route: "/services/btst-short-term-long-term" }
  ];

  return (
    <div>
      <Parallexsection
        heading="Equity"
        heading2="Equity"
      />

      <div className="service-page">
        {services.map(service => (
          <ServiceContainer key={service.name} title={service.name} route={service.route} />
        ))}
      </div>
    </div>
  );
}

export default EquityPage;

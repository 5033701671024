// Services.jsx
import React from 'react';
import derivatives from '../images/derivatives.jpg';
import './Service.css';
import ServiceCard from './ServiceCard';

const Services = () => {
  const services = [
    {
      image: 'https://wallpapercave.com/wp/wp2128235.png',
      title: 'Equity',
      route:'/equity'
    },
    {
      image: derivatives,
      title: 'Derivative',
       route:'/derivative'
    },
    // Add more services as needed
  ];

  return (
    <div className="services">
      <h2>Service We Provide</h2>
      <div className="service-cards">
        {services.map((service, index) => (
          <ServiceCard key={index} image={service.image} title={service.title} route={service.route} />
        ))}
      </div>
    </div>
  );
};

export default Services;

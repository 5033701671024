import React from 'react';
import FeaturesDemoCall from '../component/FeaturesDemoCall';
import Parallexsection from '../component/Parallexsection';
import PricingPlan from '../component/PricingPlan';

function Classicequity() {



  const monthlyPlan = {
    title: 'Monthly',
    price: 'Rs 7500',
    features: [
      '1-2 Intraday Equity signal with set Target & SL.',
      'Follow-up on trade signals.',
      'Morning & Closing Bell update.',
      'Global market & Economic data update.',
      'Stock in News, RBI Policy, IIP, CPI update.'
    ]
  };

  const quarterlyPlan = {
    title: 'Quarterly',
    price: 'Rs 18000',
    features: [
      '1-2 Intraday Equity signal with set Target & SL.',
      'Follow-up on trade signals.',
      'Morning & Closing Bell update.',
      'Global market & Economic data update.',
      'Stock in News, RBI Policy, IIP, CPI update.'
    ]
  };






  const features = [
    { label: 'Monthly', value: '12-18 Equity Cash Recommendations' },
    { label: 'Follow Up', value: 'Yes' },
    { label: 'Minimum Investment', value: '1,00,000' },
    { label: 'Mode of recommendation', value: 'SMS Only' },
    { label: 'Follow Up', value: 'Yes, SMS' },
    { label: 'Risk', value: 'High' }
  ];

  const demoCall = 'Demo call: Buy CDSL at 1225 Target 1265 Stoploss 1200';

  
  return (
    <div>
        <Parallexsection
         heading="Classic Equity"
        heading2="Classic Equity"
       
    />

  <div>

  <div>
  <FeaturesDemoCall features={features} demoCall={demoCall} />
  </div>

  <div className='pricingMasterContainer'>
<PricingPlan {...monthlyPlan} />
<PricingPlan {...quarterlyPlan} />
</div>

</div>
      
    </div>
  )
}


export default  Classicequity

import React from 'react';
import Parallexsection from '../component/Parallexsection';
import ServiceContainer from '../Service/ServiceContainer';
import './DerivativePage.css';

function DerivativePage() {

  const services = [
    { name: "Classic Stock Future", route: "/services/classic-stock-future" },
    { name: "Platinum Stock Future", route: "/services/platinum-stock-future" },
    { name: "Classic Stock Option", route: "/services/classic-stock-option" },
    { name: "Platinum Stock Option", route: "/services/platinum-stock-option" },
    { name: "Classic Index Options", route: "/services/classic-index-options" },
    { name: "Platinum Index Options", route: "/services/platinum-index-options" },
    { name: "Platinum Index Future", route: "/services/platinum-index-future" }
  ];

  return (
    <div>
      <Parallexsection
        heading="Derivative"
        heading2="Derivative"
        
      />

      <div className="service-page">
        {services.map(service => (
          <ServiceContainer key={service.name} title={service.name} route={service.route} />
        ))}
      </div>
    </div>
  );
}

export default DerivativePage;

import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import AboutPage from './About/AboutPage';
import AdminHome from './Admin/AdminHome';
import AdminLogin from './Admin/AdminLogin';
import PrivateRoute from './Admin/PrivateRoute'; // Import the PrivateRoute component
import './App.css';
import Bankdetails from './Bankdetails/Bankdetails';
import BottomNav from './Bottomav/BottomNav';
import Complaintstatus from './Complaintstatus/Complaintstatus';
import ContactPage from './Contact/ContactPage';
import Classicindexoptions from './Derivative/Classicindexoptions';
import Classicstockfuture from './Derivative/Classicstockfuture';
import Classicstockoption from './Derivative/Classicstockoption';
import DerivativePage from './Derivative/DerivativePage';
import Platinumindexoptions from './Derivative/Platinumindexoptions';
import Platinumstockfuture from './Derivative/Platinumstockfuture';
import Platinumstockoption from './Derivative/Platinumstockoption';
import DisclosuresAdvise from './DisclosuresAdvise/DisclosuresAdvise';
import Btstshorttermlongterm from './Equity/Btstshorttermlongterm';
import Classicequity from './Equity/Classicequity';
import EquityPage from './Equity/EquityPage';
import Platinumequity from './Equity/Platinumequity';
import FAQ from './FAQ/FAQ';
import Footer from './Footer/Footer';
import Home from './Home/Home';
import MainHeader from './MainHeader/MainHeader';
import ServicePage from './Service/ServicePage';
import Tracksheet from './Tracksheet/Tracksheet';

function AppContent() {
  const location = useLocation();

  // Determine if the current route is an admin route
  const isAdminRoute = location.pathname.startsWith('/Admin');

  return (
    <div className="App">
      {!isAdminRoute && <MainHeader className="Header" /> }
      {!isAdminRoute && <BottomNav /> }
      <div className="Content">
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/service" element={<ServicePage />} />
            <Route path='/bank-details' element={<Bankdetails/>}/>
            <Route path="/equity" element={<EquityPage />} />
            <Route path="/derivative" element={<DerivativePage />} />
            <Route path="/track-sheet" element={<Tracksheet />} />
            <Route path="/complaint-status" element={<Complaintstatus />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/disclosures-advise" element={<DisclosuresAdvise />} />

            <Route path="/services/classic-equity" element={<Classicequity />} />
            <Route path="/services/platinum-equity" element={<Platinumequity />} />
            <Route path="/services/btst-short-term-long-term" element={<Btstshorttermlongterm />} />

            <Route path="/services/classic-stock-future" element={<Classicstockfuture />} />
            <Route path="/services/classic-stock-option" element={<Classicstockoption />} />
            <Route path="/services/classic-index-options" element={<Classicindexoptions />} />
            <Route path="/services/platinum-stock-future" element={<Platinumstockfuture />} />
            <Route path="/services/platinum-stock-option" element={<Platinumstockoption />} />
            <Route path="/services/platinum-index-options" element={<Platinumindexoptions />} />
            <Route path="/services/platinum-index-future" element={<Btstshorttermlongterm />} />

            <Route path="/Admin" element={<AdminLogin />} />
            <Route path="/Admin/home" element={<PrivateRoute element={AdminHome} />} />
          </Routes>
        </main>
      </div>
      {!isAdminRoute && <Footer className="Footer" />}
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
